html {
    height: 100%
}

body {
    display: flex;
    background-color: #444;
    align-content: center;
    align-items: center;
    align-self: center;
    height: 100%;
    justify-content: center;
    font-family: aktiv-grotesk, "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #ffb02e;
}

h1 {

    font-weight: bold;
    text-shadow: 0px 0px 1px rgb(0 0 0 / 85%);
}